import React from 'react';
import styled from 'styled-components';
import Layout from 'components/page/Layout';
import SEO from 'components/shared/SEO';
import {
  Blebber,
  TravelWorldAndExplore,
  Mmdbstar,
} from 'components/shared/Projects';
import Portrait from 'images/sean-portrait.jpg';
import ServiceWorkerCookbook from 'images/service-worker-development-cookbook.jpg';

const Wrapper = styled.div``;

const Author = styled.section`
  border-top: #ebf2f6 0.1rem solid;
  margin-top: 7.5rem;
  margin-bottom: -5.5rem;
  display: flex;
  justify-content: center;
`;

const Figure = styled.figure`
  display: block;
  margin: 0 auto;
  margin-bottom: -55px;
  position: relative;
  left: 0;
  top: -6.5rem;
  width: 15rem;
  height: 15rem;
  border-radius: 100%;
  overflow: hidden;
  padding: 0.6rem;
  background: #fff;
  z-index: 2;
  box-shadow: #e7eef2 0 0 0 0.1rem;
`;

const Img = styled.img`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 100%;
`;

const About = () => (
  <Layout>
    <SEO title="About" />
    <Wrapper>
      <h1>ABOUT - SEAN SARANGA AMARASINGHE</h1>
      <Author>
        <Figure>
          <Img src={Portrait} />
        </Figure>
      </Author>
      <p>
        Hello! Welcome to my Blog. My name is Sean, a software developer from
        Melbourne (Australia).
      </p>
      <p>
        I am a developer, a designer, an{' '}
        <a
          href="https://www.packtpub.com/books/info/authors/sean-amarasinghe"
          alt="Self portrait"
        >
          author
        </a>
        , a <a href="https://500px.com/szaranger">photographer</a> and also a
        blogger. I blog about development, design, technology and photography.
      </p>
      <h2>Projects</h2>
      <Blebber />
      <br />
      <TravelWorldAndExplore />
      <br />
      <Mmdbstar />
      <h2>Books</h2>
      <h3>Service Worker Development Cookbook</h3>
      <p>
        Build highly available and performant native web applications that
        seamlessly integrate with third-party APIs
      </p>
      <img
        src={ServiceWorkerCookbook}
        alt="Service Worker Development Cookbook"
        width="200"
      />
    </Wrapper>
  </Layout>
);

export default About;
