import React from 'react';
import styled from 'styled-components';

import TWELogo from 'images/twe-logo.png';
import { DARK_JUNGLE_GREEN, WHITE } from 'constants/colors';

const handleClick = (url) => {
  window.open(url, '_blank');
};

/* eslint-disable max-len */
export const Blebber = () => (
  <Wrapper onClick={() => handleClick('https://www.blebber.com')}>
    <Icon color={DARK_JUNGLE_GREEN}>
      <Svg viewBox="0 0 1000 1000" width="3rem">
        <g>
          <g>
            <g>
              <path
                fill="#ffd000"
                d="M692.5,473.3l-36-13.1l34.7-16.3c85-39.8,131.8-108.3,131.8-193c0-79.9-41.6-147.8-114.1-186.4C641.5,24.7,564.4,10,423.7,10C318.1,10,204,18,143.9,29.5v946.7c37.8,5.4,115.9,13.9,233.7,13.9c181.6,0,305.6-28.5,379.3-87c64.9-52.5,99.2-122.7,99.2-202.8C856.1,593.9,796.4,511.3,692.5,473.3z M353.5,168.1l13.9-1.8c22.9-2.9,50.3-4.3,83.6-4.3c102.8,0,159.4,41.3,159.4,116.2c0,77.3-67.7,123.5-181,123.5h-75.9L353.5,168.1L353.5,168.1z M442.4,835.1c-30.5,0-54.6,0-74.3-1.7l-14.6-1.3V550.8h78.8c127.9,0,201.2,51.3,201.2,140.7C633.5,782.8,563.9,835.1,442.4,835.1z"
              />
            </g>
          </g>
        </g>
      </Svg>
    </Icon>
    <Description>
      <h3>Blebber</h3>
      <div>An online platform for learning German language</div>
      <Stack>Stack: React, Node.js and MongoDB</Stack>
    </Description>
  </Wrapper>
);

/* eslint-disable max-len */
export const TravelWorldAndExplore = () => (
  <Wrapper onClick={() => handleClick('https://www.travelworldandexplore.com')}>
    <Icon color={WHITE} padding="4px">
      <img src={TWELogo} alt="TWE logo" />
    </Icon>
    <Description>
      <h3>Travel World & Explore</h3>
      <div>
        Travel website providing tips and information for various cities around
        the world
      </div>
      <Stack>Stack: React, TypeScript, Next.js, AWS and Node.js</Stack>
    </Description>
  </Wrapper>
);

/* eslint-disable max-len */
export const Mmdbstar = () => (
  <Wrapper onClick={() => handleClick('https://www.mmdbstar.com')}>
    <Icon color={WHITE} padding="2px">
      <svg
        viewBox="0 0 73 25"
        xmlns="http://www.w3.org/2000/svg"
        className="css-5qyo6v"
      >
        <title>{'Group'}</title>
        <g fill="none" fillRule="evenodd">
          <path
            fill="#EF3A7B"
            d="m64.776 15.42-4.549 2.957 1.694-5.066-4.505-3.587h5.557l1.803-5.347 1.99 5.347h5.37l-4.265 3.587 1.454 5.066z"
          />
          <text
            fillRule="nonzero"
            fontFamily="Avenir-Black, Avenir"
            fontSize={18}
            fontWeight={700}
            letterSpacing={0.643}
          >
            <tspan x={0} y={18} fill="#FBD52B">
              {'m'}
            </tspan>
            <tspan x={16.303} y={18} fill="#F7A843">
              {'m'}
            </tspan>
            <tspan x={32.606} y={18} fill="#F36764">
              {'d'}
            </tspan>
            <tspan x={44.589} y={18} fill="#EF3A7B">
              {'b'}
            </tspan>
          </text>
        </g>
      </svg>
    </Icon>
    <Description>
      <h3>mmdbstar</h3>
      <div>A personal selection of movies with related actors and genres</div>
      <Stack>Stack: React, TypeScript, Remix.js, Node.js and MongoDB</Stack>
    </Description>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.05);
  border: 0.5px solid ${DARK_JUNGLE_GREEN};
  border-radius: 0.5rem;
  cursor: pointer;
`;

const Icon = styled.div`
  display: flex;
  background: ${(p) => p.color};
  padding: ${(p) => p.padding || 0};
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  width: 8rem;
  height: 8rem;
`;

const Description = styled.div`
  flex-direction: column;
  text-align: left;
  padding: 0.5rem 1rem;

  h3 {
    margin-bottom: 0;
  }
`;

const Stack = styled.div`
  margin-top: 0.3rem;
  color: rgba(0, 0, 0, 0.75);
`;

const Svg = styled.svg`
  margin: 2.5rem;
`;
